<template>
  <v-card class="mx-auto" max-width="800" flat>
    <v-card dark flat>
      <v-card-title class="pa-2 indigo">
        <h3 class="text-h6 font-weight-light text-center grow">
          {{ title }}
        </h3>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-dialog v-model="dialog" persistent max-width="550">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              absolute
              bottom
              v-bind="attrs"
              v-on="on"
              right
              fab
              color="green"
            >
              <v-icon>mdi-file-document-plus</v-icon>
            </v-btn>
          </template>
          <v-card width="600">
            <v-card-title class="text-h5"> Add Comment </v-card-title>
            <v-card-text>
              <v-select
                v-model="comment_type"
                label="Type"
                :rules="[(v) => !!v || 'Type required']"
                :items="comment_types"
                :item-value="'id'"
                :item-text="'label'"
              >
              </v-select>
              <v-textarea
                v-model="comment"
                :rules="[(v) => !!v || 'Comment required']"
                label="Comment"
              >
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn :loading="progress" color="primary" @click="save_comment">
                Save
              </v-btn>
              <v-btn color="error" @click="dialog = false"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
    <v-card-text>
      <template>
        <v-container>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(comments, comment_type) in groupedComments"
              :key="comment_type"
            >
              <v-expansion-panel-header
                :style="{
                  color: comment_color(comment_type),
                }"
                >{{ comment_name(comment_type) }}</v-expansion-panel-header
              >
              <v-expansion-panel-content
                :style="{
                  borderColor: 'blue',
                  borderStyle: 'solid',
                }"
              >
                <v-list>
                  <v-list-item
                    v-for="(comment, index) in comments"
                    :key="index"
                  >
                    <v-list-item-content
                      :style="{
                        color: comment_color(comment_type),
                      }"
                    >
                      <v-list-item-title>{{ comment.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        comment.date
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import CREATE_NOTE from "../graphql/CREATE_NOTE.gql";
import { dateam } from "@/utils/functions.js";
export default {
  props: {
    title: String,
    document_type: Number,
    doc_id: Number,
    comments: Array,
  },
  data() {
    return {
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      comment_types: [
        {
          id: "1",
          label: "Drilling End",
          icon: "mdi-phone-classic",
          color: "lightblue",
        },
        { id: "2", label: "Mud logger", icon: "mdi-email", color: "blue" },
        {
          id: "3",
          label: "Geologisit",
          icon: "mdi-account-multiple",
          color: "cyan",
        },
        {
          id: "4",
          label: "Petrophysicist",
          icon: "mdi-account-tie-voice",
          color: "orange",
        },
        {
          id: "5",
          label: "Workover Eng",
          icon: "mdi-account-tie-voice",
          color: "lightcoral",
        },
        {
          id: "6",
          label: "Operation Eng",
          icon: "mdi-account-tie-voice",
          color: "purpule",
        },
        {
          id: "6",
          label: "Well integrity Eng",
          icon: "mdi-account-tie-voice",
          color: "lightgreen",
        },
        {
          id: "7",
          label: "Well history summary",
          icon: "list-box-outline",
          color: "lightorange",
        },
      ],

      groupedComments: {},
    };
  },
  computed: {
    dateam() {
      return dateam;
    },
  },
  created() {
    this.groupCommentsByDepartment();
  },
  methods: {
    groupCommentsByDepartment() {
      this.groupedComments = this.comments.reduce((acc, comment) => {
        if (!acc[comment.comment_type]) {
          acc[comment.comment_type] = [];
        }
        acc[comment.comment_type].push({
          text: comment.comment,
          date: comment.created_at,
        });
        return acc;
      }, {});
    },

    comment_name(id) {
      let n;
      let i = this.comment_types.findIndex((elm) => elm.id == id);
      if (i >= 0) n = this.comment_types[i].label;
      return n;
    },
    comment_color(id) {
      let n;
      let i = this.comment_types.findIndex((elm) => elm.id == id);
      if (i >= 0) n = this.comment_types[i].color;
      return n;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save_comment() {
      if (this.$refs.form.validate()) {
        let i = this.comment_types.findIndex(
          (elm) => elm.id == this.comment_type
        );
        let icon;
        if (i >= 0) icon = this.comment_types[i].icon;
        let v = {
          note: {
            document_type: this.document_type,
            document_id: parseInt(this.doc_id),
            comment: this.comment,
            comment_type: parseInt(this.comment_type),
            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let id = await this.maj(CREATE_NOTE, v);
        if (id) {
          this.comments.push({
            id: id,
            comment: this.comment,
            icon: icon,
            user_name: this.$store.state.me.name,
            comment_type: parseInt(this.comment_type),
            create_uid: parseInt(this.$store.state.me.id),
            created_at: this.$store.state.today,
          });
          this.comment = null;
          this.dialog = false;
          this.groupCommentsByDepartment();
        }
      }
    },
  },
};
</script>
