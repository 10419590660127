var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800","flat":""}},[_c('v-card',{attrs:{"dark":"","flat":""}},[_c('v-card-title',{staticClass:"pa-2 indigo"},[_c('h3',{staticClass:"text-h6 font-weight-light text-center grow"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","bottom":"","right":"","fab":"","color":"green"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"width":"600"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Add Comment ")]),_c('v-card-text',[_c('v-select',{attrs:{"label":"Type","rules":[function (v) { return !!v || 'Type required'; }],"items":_vm.comment_types,"item-value":'id',"item-text":'label'},model:{value:(_vm.comment_type),callback:function ($$v) {_vm.comment_type=$$v},expression:"comment_type"}}),_c('v-textarea',{attrs:{"rules":[function (v) { return !!v || 'Comment required'; }],"label":"Comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.progress,"color":"primary"},on:{"click":_vm.save_comment}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1),_c('v-card-text',[[_c('v-container',[_c('v-expansion-panels',_vm._l((_vm.groupedComments),function(comments,comment_type){return _c('v-expansion-panel',{key:comment_type},[_c('v-expansion-panel-header',{style:({
                color: _vm.comment_color(comment_type),
              })},[_vm._v(_vm._s(_vm.comment_name(comment_type)))]),_c('v-expansion-panel-content',{style:({
                borderColor: 'blue',
                borderStyle: 'solid',
              })},[_c('v-list',_vm._l((comments),function(comment,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{style:({
                      color: _vm.comment_color(comment_type),
                    })},[_c('v-list-item-title',[_vm._v(_vm._s(comment.text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(comment.date))])],1)],1)}),1)],1)],1)}),1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }